<template>
  <form action="" @submit.stop.prevent="() => {}">
    <div class="form-row desktop_form" v-if="type === 'tabs'">
      <div class="form-group col-md-3">
        <Select
          @changeDestination="destChanged"
          @optionSelect="destSelect"
          :list="destinationList"
        />
      </div>

      <div class="form-group col-md-3">
        <HotelSelect
          :destination="destinationCityName"
          :hotelListShow="destinationChanged"
          @changeHotel="hotelChanged"
          @optionSelect="hotelSelect"
          :list="hotelList"
        />
      </div>

      <div class="form-group col-md-3">
        <app-datepicker
          :calendarShow="destinationChanged && hotelListChanged"
          @finalDateSelect="finalDateSelect"
          @dates="chooseDate"
        ></app-datepicker>
      </div>
      <div class="form-group col-md-2">
        <Dropdown @changeBodyCount="changeCount" :dropdownShow="dropdownShow"/>
      </div>
      <div class="form-group col-md-1 d-flex">
        <button class="btn flex-fill" @click="submitSearch" :disabled="searchDisable" style="padding:4px;">
          {{ $t("search-tab.search") }}
          <b-spinner small v-if="isLoading"></b-spinner>
          <i class="fas fa-search" v-else></i>
        </button>
      </div>
    </div>

    <Breadcrumb :data="linkDataSearch" v-if="linkDataSearch && breadcrumb==='breadcrumb'" :destination="getBreadcrumb" :page="`search`"/>

    <!-- <MobileSearchForm/> -->
    <div class="card card-body boxSideSearch border-0" :class="{ mobile_form: type === 'tabs' }">
      <div class="form-row">
        <div class="form-group col-12">
          <label for="serchItem">{{
            $t("search-tab.mobile-form.destination-label")
          }}</label>
          <Select
            @changeDestination="destChanged"
            @optionSelect="destSelect"
            :list="destinationList"
          />
        </div>

        <div class="form-group col-12">
          <label for="serchItem">{{
            $t("search-tab.mobile-form.hotel-label")
          }}</label>
          <HotelSelect
            :destination="destinationCityName"
            :hotelListShow="destinationChanged"
            @changeHotel="hotelChanged"
            @optionSelect="hotelSelect"
            :list="hotelList"
            :multiple="true"
          />
        </div>

        <div class="form-group col-12">
          <label for="startDate">{{ $t("search-tab.mobile-form.start-date") }}</label>
          <div class="input-group">
            <app-datepicker
              :calendarShow="destinationChanged && hotelListChanged"
              @dates="chooseDate"
              type="side"
            ></app-datepicker>
          </div>
        </div>
        <div class="form-group col-4">
          <b-form-select class="form-control" v-model="countAdult">
            <template #first>
              <b-form-select-option :value="0" disabled>{{
                $t("search-tab.adult")
              }}</b-form-select-option>
            </template>
            <option v-for="inx in 6" :key="inx" :value="inx - 1">{{ inx - 1 }}</option>
          </b-form-select>
        </div>
        <div class="form-group col-4">
          <b-form-select class="form-control" v-model="countChild">
            <template #first>
              <b-form-select-option :value="0" disabled>{{
                $t("search-tab.child")
              }}</b-form-select-option>
            </template>
            <option v-for="inx in 6" :key="inx" :value="inx - 1">{{ inx - 1 }}</option>
          </b-form-select>
        </div>
        <div class="form-group col-4">
          <b-form-select class="form-control" v-model="countInfant">
            <template #first>
              <b-form-select-option :value="0" disabled>{{
                $t("search-tab.infant")
              }}</b-form-select-option>
            </template>
            <option v-for="inx in 6" :key="inx" :value="inx - 1">{{ inx - 1 }}</option>
          </b-form-select>
        </div>
        <div class="form-group col-6 d-flex">
          <button
            class="btn flex-fill btn-outline-dark btn-search"
            @click="submitSearch"
            :disabled="searchDisable"
          >
            {{ $t("search-tab.search") }}
            <b-spinner v-if="isLoading" small></b-spinner>
            <i class="fas fa-search" v-else></i>
          </button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  BFormSelect,
  BFormSelectOption,
  BSpinner,
} from 'bootstrap-vue';

export default {
  name: 'SearchTab',
  components: {
    Dropdown: () => import('@/components/atoms/Dropdown'),
    Select: () => import('@/components/atoms/SearchSelect'),
    HotelSelect: () => import('@/components/atoms/MultipleSelect'),
    'app-datepicker': () => import('@/components/atoms/CustomDatepicker'),
    Breadcrumb: () => import('@/components/productPage/Breadcrumb'),
    BFormSelect,
    BFormSelectOption,
    BSpinner,
  },
  props: {
    breadcrumb: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'tabs',
    },
  },
  data() {
    return {
      destination: '',
      selectedHotels: [],
      hotelList: [],
      fromDate: '',
      toDate: '',
      destinationChanged: false,
      hotelListChanged: false,
      dropdownShow: false,
      hotelName: '',
      destinationText: '',

      supplierListForSearch: process.env.VUE_APP_SUPPLIER_SEARCH_LIST.split(','),
    };
  },
  mounted() {
    const thisInstance = this;
    this.$root.$on('callBreadcrumbEvent', () => {
      thisInstance.callBreadcrumb();
    });
  },
  computed: {
    ...mapGetters({
      destinationList: 'GET_CAMINGO_DESTINATION',
      totalHotelList: 'GET_CAMINGO_HOTELS',
      isLoading: 'GET_LOADING_STATE',
      lang: 'GET_LANGUAGE',
      isLanding: 'GET_IS_LANDING_PAGE',
      marketerId: 'GET_MARKETER_ID',
      hotelAllInfo: 'GET_HOTEL_ALL_INFO',
    }),
    query() {
      const route = this.$route;
      return route.query;
    },
    cityId() {
      return this.query.city;
    },
    hotelCode() {
      return this.query.hotelCode;
    },
    linkDataSearch() {
      return {
        dest: this.destinationCityName,
      };
    },
    searchDisable() {
      return !this.destination || !this.fromDate || !this.toDate || !this.countAdult || this.selectedHotels.length === 0;
    },
    countAdult: {
      get() {
        return this.$store.getters.GET_SEARCH_CONTENT.adult ? this.$store.getters.GET_SEARCH_CONTENT.adult : 2;
      },
      set(value) {
        this.$store.dispatch('SET_SEARCH_ITEM', ['adult', value]);
      },
    },
    countChild: {
      get() {
        return this.$store.getters.GET_SEARCH_CONTENT.child ? this.$store.getters.GET_SEARCH_CONTENT.child : 0;
      },
      set(value) {
        this.$store.dispatch('SET_SEARCH_ITEM', ['child', value]);
      },
    },
    countInfant: {
      get() {
        return this.$store.getters.GET_SEARCH_CONTENT.infant ? this.$store.getters.GET_SEARCH_CONTENT.infant : 0;
      },
      set(value) {
        this.$store.dispatch('SET_SEARCH_ITEM', ['infant', value]);
      },
    },
    destinationCityName() {
      const filterArray = this.destinationList.filter((item) => item.code === this.destination);

      return filterArray.length > 0 ? filterArray[0].nameTranslations[this.lang] : '';
    },
    destinationName() {
      return this.destination;
    },
    getBreadcrumb() {
      const landingUrl = this.$route.fullPath;
      let hotelName = '';
      if (this.hotelAllInfo.length && this.hotelCode) {
        const hotelInfo = this.hotelAllInfo;
        const hotel = hotelInfo.find((elm) => elm.hotelConversion?.hotelExternalCode === this.hotelCode);
        hotelName = hotel?.hotelConversion?.hotelName || '';
      }

      let destCode = '';
      let destSnd = '';
      let destThird = '';
      let destinationText = '';
      switch (this.$route.name) {
        case 'search-result': {
          destCode = this.$route.query.city;
          const filterArray = this.destinationList.filter((item) => item.code === destCode);
          destinationText = filterArray.length > 0 ? filterArray[0].nameTranslations[this.lang] : '';
          destSnd = this.$t('hotel-page.hotel-breadcrumb-hotel-sec', { destinationName: destinationText });
          break;
        }
        case 'hotels-page': {
          if (hotelName === '' || hotelName === undefined) {
            destCode = landingUrl.split('=')[1];
            const filterArray = this.destinationList.filter((item) => item.code === destCode);
            destSnd = filterArray.length > 0 ? filterArray[0].nameTranslations[this.lang] : '';
            destThird = this.$t('hotel-page.hotel-in-dest', { destinationName: destSnd });
          } else {
            destCode = landingUrl.split('=')[1];
            destCode = destCode.split('&')[0];
            const filterArray = this.destinationList.filter((item) => item.code === destCode);
            destinationText = filterArray.length > 0 ? filterArray[0].nameTranslations[this.lang] : '';
            destSnd = this.$t('hotel-page.hotel-breadcrumb-hotel-sec', { destinationName: destinationText });
            destThird = this.$t('hotel-page.hotel-breadcrumb-hotel-third', { hotelname: hotelName, destinationName: destinationText });
          }
          break;
        }
        case 'home': {
          const params = this.$route.query;
          if (params.destination) {
            destCode = landingUrl.split('=')[1];
            const filterArray = this.destinationList.filter((item) => item.code === destCode);
            destinationText = filterArray.length > 0 ? filterArray[0].nameTranslations[this.lang] : '';
            destSnd = this.$t('hotel-page.hotel-breadcrumb-dest-sec', { destinationName: destinationText });
            destThird = this.$t('hotel-page.hotel-breadcrumb-dest-third', { destinationName: destinationText });
            break;
          }

          if (params.chain) {
            destinationText = landingUrl.split('=')[1];
            destinationText = decodeURIComponent(destinationText);
            destSnd = this.$t('hotel-page.hotel-breadcrumb-chain-sec', { destinationName: destinationText });
            destThird = this.$t('hotel-page.hotel-breadcrumb-chain-third', { destinationName: destinationText });
            break;
          }

          break;
        }

        default: {
          destCode = '';
          break;
        }
      }

      return {
        hotelName,
        destSnd,
        destThird,
      };
    },
  },
  watch: {
    lang() {
    },
  },
  methods: {
    async submitSearch(event) {
      event.preventDefault();
      if (this.isLoading) {
        this.$bvModal.msgBoxOk(this.$t('search-tab.is-loading-warning-message'), {
          title: this.$t('search-tab.is-loading-warning-title'),
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'success',
          headerClass: 'p-2 border-bottom-0',
          footerClass: 'p-2 border-top-0',
          centered: true,
        });
        return false;
      }
      if (this.type === 'mobile') {
        this.$emit('closeSearchMobile');
      }
      this.$root.$emit('setTimer');
      if (this.searchDisable) {
        return false;
      }
      const allSelected = this.selectedHotels[0].id === 0;
      const externalHotels = this.selectedHotels.filter((h) => (h.source !== 4));
      const hotelsCodes = allSelected ? [] : this.selectedHotels.map((item) => item.code);
      const sourceArray = allSelected ? [] : this.selectedHotels.map((item) => item.source);
      if (!allSelected) {
        hotelsCodes.push(...externalHotels.map((h) => h.camingoCode));
        sourceArray.push(...externalHotels.map(() => 4));
      }

      let body = {
        city: this.destination,
        hotelCode: hotelsCodes.join(','),
        checkIn: this.fromDate,
        checkOut: this.toDate,
        adult: this.countAdult,
        child: this.countChild,
        infant: this.countInfant,
        lang: 'he',
        includeFlight: null,
        suppliers: this.supplierListForSearch.join(','),
        returnLog: null,
      };
      if (!allSelected) body.sources = sourceArray.join(',');

      const { query } = this.$route;
      const { marketerId } = this;
      if (marketerId) body = { ...body, marketerId };
      if (Object.keys(body).every((key) => String(body[key]) === String(query[key]))) return false;

      this.$router.push({ path: '/search-result', query: body });
      if (this.isLanding) {
        setTimeout(() => {
          this.$router.go();
        }, 10);
      }
      return true;
    },
    callBreadcrumb() {
      return this.getBreadcrumb;
    },
    resetDate() {
      this.$store.dispatch('SET_SEARCH_ITEM', ['from', '']);
      this.$store.dispatch('SET_SEARCH_ITEM', ['to', '']);
    },
    destSelect(value) {
      this.destinationChanged = value;
    },
    hotelSelect(value) {
      this.hotelListChanged = value;
    },
    destChanged(dest) {
      if (!dest || !this.totalHotelList.data) return false;

      this.resetDate();
      this.destination = dest.value;

      this.hotelList = this.totalHotelList.data.filter((item) => item.mainCityCode === this.destination);
      return true;
    },
    hotelChanged(hotels) {
      this.selectedHotels = hotels;// (hotels.length > 0) ? hotels.map((item) => item.value) : [];
    },
    chooseDate(date) {
      this.fromDate = date.from;
      this.toDate = date.to;
    },
    finalDateSelect(value) {
      this.dropdownShow = value;
    },
    changeCount({ adult, child, infant }) {
      this.countAdult = adult;
      this.countChild = child;
      this.countInfant = infant;
    },
  },
};
</script>

<style scoped>
@media (max-width: 479px) {
  .mobile_form {
    display: block;
  }
  .desktop_form {
    display: none;
  }
}
.custom-control {
  padding-right: 1rem;
}
</style>

<style>
.custom-control-input {
  left: auto;
  right: 0;
}
.filter-tab .custom-control-label::before,
.filter-tab .custom-control-label::after {
  right: -1.5rem;
  left: auto;
}
.vs__dropdown-toggle {
  background: #e9ecef !important;
}
</style>
